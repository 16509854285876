import React, { FC, ReactElement } from "react"

import { IProductBannerTitle } from "gatsby-theme-nurofen/src/components/ProductBanner/ProductBannerTitle/model"
import DangerouslySetInnerHtml from "gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml"

const ProductBannerTitle: FC<IProductBannerTitle> = ({
  title,
}): ReactElement => (
  <DangerouslySetInnerHtml
    className="nf-product-banner__product-title"
    element="h1"
    html={title}
  />
)

export default ProductBannerTitle
